export const solution = {
  mode: "ko",
  solution: {
    title: "셀디와 함께하면,",
    emphasize: "한번에 해결 !",
    one: "‘상품 관리, 주문 관리, 물류 관리, 수출 신고’ > 자동화",
    two: "무분별한 왕홍 마케팅 > 최적화",
    three: "어려운 수출 데이터 분석 > 맞춤화",
    description:
      "단순한 수출 행정업무부터, 복잡한 마케팅 설계까지, 그 동안 경험에만 의존했던 확률적인 마케팅을 셀디를 통한 ‘5분 세팅’만으로도 최적화할 수 있습니다",
  },
};
